<template>
  <div class="info-nav">
    <span
      :class="['nav-item', value == index ? 'active' : '']"
      v-for="(item, index) in list"
      :key="index"
      @click="change(index)"
      >{{ item }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    change(idx) {
      this.$emit("change", idx);
    },
  },
};
</script>

<style lang="scss" scoped>
.info-nav {
  width: 100%;
  padding: 26px 32px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  .nav-item {
    font-size: 18px;

    font-weight: 400;
    color: #666666;
    position: relative;
    margin-right: 44px;
    cursor: pointer;
    &::after {
      content: "";
      width: 0;
      height: 2px;
      background: #0086ff;
      position: absolute;
      bottom: -26px;
      left: 50%;
      transform: translateX(-50%);
    }
    &.active {
    }
    &.active::after {
      content: "";
      width: 100%;
      transition: width 0.6s;
    }
  }
}
</style>
