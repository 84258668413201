<template>
  <div class="info">
    <base-nav :list="navList" v-model="current" />
    <div class="content-box">
      <div class="info-box">
        <div class="info-name">昵称</div>
        <div class="info-input">
          <el-input
            v-model="userinfo.nickname"
            placeholder="请输入昵称"
            :clearable="true"
          ></el-input>
        </div>
      </div>
      <div class="info-box">
        <div class="info-name">真实姓名</div>
        <div class="info-input">
          <el-input
            v-model="userinfo.realname"
            placeholder="请输入个人真实姓名"
            :clearable="true"
          ></el-input>
        </div>
      </div>
      <div class="info-box">
        <div class="info-name">手机号码</div>
        <div class="info-input">
          <el-input
            v-model="userinfo.mobile"
            type="Number"
            placeholder="请输入手机号码"
            :disabled="true"
            :clearable="true"
          ></el-input>
        </div>
        <div class="info-btn" @click="changPhone">更换手机号</div>
      </div>
      <div class="info-box">
        <div class="info-name">性别</div>
        <div class="info-radio">
          <el-radio-group v-model="userinfo.sex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
            <el-radio :label="0">保密</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="info-box">
        <div class="info-name">出生年月</div>
        <div class="info-input">
          <el-date-picker
            v-model="userinfo.birthday"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="info-box">
        <div class="info-name">个人简介</div>
        <div class="info-textarea">
          <el-input
            type="textarea"
            placeholder="请输入个人简介..."
            v-model="userinfo.remark"
          >
          </el-input>
        </div>
      </div>
      <div class="info-btn-box">
        <div class="ib-btn sumbit" @click="onSave" v-loading="loading">
          保存
        </div>
      </div>
    </div>
    <el-dialog
      title="更换手机"
      :visible.sync="Visible"
      width="540px"
      :append-to-body="true"
      @close="onClose"
    >
      <el-form
        :model="dataForm"
        :rules="rules"
        ref="dataForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="手机号" prop="phone">
          <el-input
            type="number"
            v-model="dataForm.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input
            type="number"
            v-model="dataForm.code"
            placeholder="请输入验证码"
            style="width: 260px"
          ></el-input>
          <el-button
            type="primary"
            style="margin-left: 20px"
            @click="getCode(5)"
            >{{ codeName }}</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose" v-loading="loading">取 消</el-button>
        <el-button type="primary" @click="onSubmit" v-loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Api from "@/api";
import baseNav from "./base-nav";
import login from "@/mixins/login.js";
export default {
  components: {
    baseNav,
  },
  mixins: [login],
  data() {
    const validateIsPhone = function (rule, value, callback) {
      if (value === "") {
        callback(new Error(`请输入${this.msg}`));
      } else if (!isPhone(value)) {
        callback(new Error(`${this.msg}格式不对`));
      } else {
        callback();
      }
    };
    const isPhone = function (value) {
      const reg = /^1\d{10}$/;
      return reg.test(value);
    };
    return {
      navList: ["个人基本资料"],
      current: 0,
      userinfo: {},
      dataForm: {
        phone: "",
        code: "",
      },
      Visible: false,
      rules: {
        phone: {
          required: true,
          validator: validateIsPhone.bind({ msg: "手机号" }),
          trigger: "blur",
        },
        code: { required: true, message: "验证码不能为空", trigger: "blur" },
      },
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {
      Api.UserDetail({
        fk_user_id: this.userInfo.fk_user_id,
      }).then((res) => {
        if (res.status == 1) {
          this.userinfo = res.data || {};
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onClose() {
      this.Visible = false;
      this.dataForm = {
        phone: "",
        code: "",
      };
      clearTimeout(this.timer);
    },
    changPhone() {
      this.Visible = true;
    },
    onSubmit() {
      this.$refs.dataForm.validate((valid) => {
        if (!valid) {
          this.$message.error("请正确填写资料");
        }
        this.loading = true;
        Api.ChangeMobile({
          mobile: this.dataForm.phone,
          smscode: this.dataForm.code,
          fk_user_id: this.userInfo.fk_user_id,
        }).then((res) => {
          if (res.status == 1) {
            this.$message({
              message: "换绑成功",
              type: "success",
            });
            this.userinfo = res.data;
            this.$uStore("userInfo", res.data);
            this.loading = false;
            this.onClose();
          } else {
            this.$message.error(res.msg);
            this.loading = false;
          }
        });
      });
    },
    onSave() {
      this.loading = true;
      Api.ModifyUser({
        fk_user_id: this.userInfo.fk_user_id,
        realname: this.userinfo.realname,
        nickname: this.userinfo.nickname,
        birthday: this.userinfo.birthday,
        remark: this.userinfo.remark,
        sex: this.userinfo.sex,
      }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.userinfo = res.data;
          this.$uStore("userInfo", res.data);
          this.loading = false;
        } else {
          this.$message.error(res.msg);
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./base.scss";
::v-deep .el-textarea__inner {
  padding: 15px;
}
</style>
