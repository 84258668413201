<template>
  <div class="Collection">
    <base-nav :list="navList" v-model="current" @change="change" />
    <div class="content-box">
      <div
        class="item"
        v-for="(item, index) in dataList"
        :key="index"
        @click="openDetail(item.fk_info_id)"
      >
        <div class="item-right">
          <div class="right-name line-1">
            <div class="type">{{ item.status }}</div>
            <div class="name">
              {{ item.name }}
            </div>
          </div>
          <div class="right-title">
            {{ item.content }}
          </div>
          <div class="right-bottom">
            <div class="rb-left">
              <span class="left-views">评论数：{{ item.comment_count }}</span>
              <span class="left-views">点赞数：{{ item.fabulous_count }}</span>
              <span>留言于：{{ item.date_creation }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="kong" v-if="dataList.length <= 0">
        <el-empty
          image="https://eduapi.114kaili.com/Content/images/no_data.png"
          :image-size="303"
          description="暂无数据"
        ></el-empty>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :background="true"
        @prev-click="handleCurrentChange"
        @next-click="handleCurrentChange"
        :page-size="dataListParams.pageSize"
        layout="total, prev, pager, next"
        :total="dataListParams.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import baseNav from "./base-nav";
import tableMinxin from "@/mixins/tableMinxin.js";
export default {
  components: {
    baseNav,
  },
  mixins: [tableMinxin],
  data() {
    return {
      navList: ["教研课程", "资源分享"],
      current: 0,
      searchForm: {
        type: 1,
      },
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {
      this._init_data("UserComment", {
        fk_user_id: this.userInfo.fk_user_id,
        ...this.searchForm,
      });
    },
    change(idx) {
      this.current = idx;
      this.searchForm.type = idx == 1 ? 2 : 1;
      this.initPage();
    },
    openDetail(id) {
      let url =
        this.$store.state.weburl +
        (this.current == 1 ? "/resourse_detail" : "/study_detail") +
        "?id=" +
        id;
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.Collection {
  width: 100%;
  .kong {
    width: 100%;
    margin: 0 auto;
  }
  .content-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    .item {
      width: 100%;
      padding: 20px 50px;
      display: flex;
      box-sizing: border-box;
      border-bottom: 1px solid #eeeeee;
      cursor: pointer;
      .item-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        .right-name {
          width: 100%;
          font-size: 18px;

          font-weight: bold;
          color: #000000;
          padding-bottom: 22px;
          display: flex;
          align-items: center;
          .type {
            width: 44px;
            height: 20px;
            background: #ff1f00;
            border-radius: 4px;
            font-size: 12px;
            padding: 0 5px;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
          }
          .name {
            flex: 1;
          }
        }
        .right-title {
          width: 100%;
          font-size: 14px;

          font-weight: 400;
          color: #666666;
          padding-bottom: 20px;
        }
        .right-bottom {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .rb-left {
            font-size: 12px;

            font-weight: 400;
            color: #999999;
            .left-views {
              padding-right: 30px;
            }
          }
          .rb-right {
            width: 80px;
            height: 34px;
            background: #0086ff;
            border-radius: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;

            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
  }
  .pagination {
    width: 100%;
    padding: 40px 0 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
