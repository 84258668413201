<template>
  <div class="detail">
    <base-nav :list="navList" v-model="current" />
    <div class="content-box">
      <div class="info-box">
        <div class="info-name">所属学校</div>
        <div class="info-input">
          <el-input
            v-model="dataForm.school"
            placeholder="请输入任职的学校名称"
            :clearable="true"
          ></el-input>
        </div>
      </div>
      <div class="info-box">
        <div class="info-name">任教年级</div>
        <div class="info-radio">
          <el-checkbox-group v-model="dataForm.grade">
            <el-checkbox label="高一">高一</el-checkbox>
            <el-checkbox label="高二">高二</el-checkbox>
            <el-checkbox label="高三">高三</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="info-box">
        <div class="info-name">任教学科</div>
        <div class="info-btn-radio">
          <el-checkbox-group v-model="dataForm.subject">
            <el-checkbox-button
              v-for="(item, index) in subjectList"
              :label="item"
              :key="index"
              >{{ item }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
      </div>
      <div class="info-btn-box">
        <div class="ib-btn sumbit" v-loading="loading" @click="onSubmit">
          保存
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseNav from "./base-nav";
import Api from "@/api";
export default {
  components: {
    baseNav,
  },
  data() {
    return {
      navList: ["认证教师资料"],
      current: 0,
      subjectList: ["语文", "数学", "英语", "物理", "历史"],
      dataForm: {},
      loading: false,
    };
  },
  created() {
    this.dataForm = { ...this.userInfo };
    this.initPage();
  },
  methods: {
    initPage() {
      this.dataForm.grade = this.dataForm.grade
        ? this.dataForm.grade.split(",")
        : [];
      this.dataForm.subject = this.dataForm.subject
        ? this.dataForm.subject.split(",")
        : [];
    },
    onSubmit() {
      this.loading = true;
      Api.UpdateTeacher({
        fk_user_id: this.userInfo.fk_user_id,
        school: this.dataForm.school,
        grade: this.dataForm.grade.join(","),
        subject: this.dataForm.subject.join(","),
      }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.dataForm = res.data;
          this.initPage();
          this.$uStore("userInfo", res.data);
          this.loading = false;
        } else {
          this.$message.error(res.msg);
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./base.scss";
</style>
