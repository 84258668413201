<template>
  <div class="user">
    <div class="content-box">
      <div class="content-banner">
        <img class="search-img" src="@/assets/home/ic_2.png" alt="" />
      </div>
      <div class="conter-view">
        <div class="view-nav">
          <div class="nav-content">
            <div
              :class="['va-item', index == current ? 'active' : '']"
              v-for="(item, index) in navList"
              :key="index"
              @click="changeNav(index)"
            >
              <img
                class="item-icon"
                :src="item.selecticon"
                alt=""
                v-if="index == current"
              />
              <img class="item-icon" :src="item.icon" alt="" v-else />
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="view-content">
          <base-info v-if="current == 0" />
          <teacher-detail v-else-if="current == 1" />
          <password v-else-if="current == 2" />
          <head-portrait v-else-if="current == 3" />
          <base-collection v-else-if="current == 4" />
          <base-leaving v-else-if="current == 5" />
          <browse-records v-else-if="current == 6" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseInfo from "./components/baserInfo";
import teacherDetail from "./components/teacherDetail";
import password from "./components/password";
import headPortrait from "./components/headPortrait";
import baseCollection from "./components/base-collection";
import baseLeaving from "./components/base-leaving";
import browseRecords from "./components/browseRecords";
export default {
  components: {
    baseInfo,
    teacherDetail,
    password,
    headPortrait,
    baseCollection,
    baseLeaving,
    browseRecords,
  },
  data() {
    return {
      navList: [
        {
          name: "个人基本资料",
          icon: require("@/assets/user/ic_6.png"),
          selecticon: require("@/assets/user/ic_6_fill.png"),
        },
        {
          name: "认证教师资料",
          icon: require("@/assets/user/ic_7.png"),
          selecticon: require("@/assets/user/ic_7_fill.png"),
        },
        {
          name: "修改登录密码",
          icon: require("@/assets/user/ic_8.png"),
          selecticon: require("@/assets/user/ic_8_fill.png"),
        },
        {
          name: "修改账户头像",
          icon: require("@/assets/user/ic_9.png"),
          selecticon: require("@/assets/user/ic_9_fill.png"),
        },
        {
          name: "我的收藏信息",
          icon: require("@/assets/user/ic_10.png"),
          selecticon: require("@/assets/user/ic_10_fill.png"),
        },
        {
          name: "我的留言记录",
          icon: require("@/assets/user/ic_11.png"),
          selecticon: require("@/assets/user/ic_11_fill.png"),
        },
        {
          name: "资源浏览记录",
          icon: require("@/assets/user/ic_12.png"),
          selecticon: require("@/assets/user/ic_12_fill.png"),
        },
      ],
      current: 0,
    };
  },
  mounted() {
    this.getTitle(6);
    if (!this.userInfo.fk_user_id) {
      this.$router.replace("/");
    }
    let index = localStorage.getItem("userCurrent");
    if (index) {
      this.current = index;
    }
  },
  destroyed() {
    localStorage.setItem("userCurrent", 0);
  },
  methods: {
    changeNav(idx) {
      this.current = idx;
      localStorage.setItem("userCurrent", idx);
    },
  },
};
</script>

<style lang="scss" scoped>
.user {
  background: url("../../assets/user/banner.png") no-repeat;
  background-color: #f5f5f5;
  background-position: center top;
  width: 100%;
  .content-box {
    width: 1200px;
    margin: 0 auto;
    .content-banner {
      padding-top: 30px;
      width: 603px;
      display: flex;
      flex-direction: column;
      .search-img {
        width: 60%;
        height: auto;
      }
    }
    .conter-view {
      padding: 70px 0 170px;
      width: 100%;
      display: flex;
      .view-nav {
        width: 226px;
        .nav-content {
          width: 100%;
          background: #ffffff;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          padding: 8px 0;
          .va-item {
            flex: 1;
            height: 34px;
            padding: 22px 44px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;

            font-weight: 400;
            color: #666666;
            cursor: pointer;
            &.active {
              color: #0086ff;
              position: relative;
            }
            &.active::after {
              content: "";
              width: 3px;
              height: 34px;
              background-color: #0086ff;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
            .item-icon {
              width: 16px;
              height: 16px;
              margin-right: 10px;
            }
          }
        }
      }
      .view-content {
        margin-left: 20px;
        width: 954px;
        background-color: #fff;
        position: relative;
        z-index: 10;
      }
    }
  }
}
</style>
