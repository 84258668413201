<template>
  <div class="headPortrait">
    <base-nav :list="navList" v-model="current" />
    <div class="content-box">
      <div class="img-box">
        <div class="img-box-garden">
          <img class="header-img-garden" :src="dataForm.headimgurl" alt="" />
          <div class="img-tip">头像预览</div>
          <div class="upload-tip">图片仅支持jpg，png格式，大小不能超过5M</div>
        </div>
      </div>
      <div class="info-btn-box">
        <!-- <el-upload
          class="upload-demo"
          action="https://eduapi.114kaili.com/WebApi/UploadFile"
          :data="{
            fk_business_id: $store.state.businessId,
          }"
          :show-file-list="false"
          :on-success="onSuccess"
          :before-upload="beforeAvatarUpload"
          :on-error="onError"
        > -->
        <div class="ib-btn sumbit" @click="upload">上传本地图片</div>
        <input
          style="display: none"
          ref="fileRef"
          type="file"
          @change="onChange"
        />
        <!-- </el-upload> -->
        <div class="ib-btn" v-loading="loading" @click="onSave">保存设置</div>
      </div>
    </div>
    <el-dialog
      :visible.sync="visible"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="800px"
      @close="onClose"
    >
      <div class="cropper-box">
        <vueCropper
          ref="cropper"
          :img="img"
          :autoCropWidth="400"
          :autoCropHeight="400"
          :autoCrop="true"
          :fixedBox="true"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose" v-loading="loading">取 消</el-button>
        <el-button type="primary" @click="onSubmit" v-loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import baseNav from "./base-nav";
import { VueCropper } from "vue-cropper";
import Api from "@/api";
export default {
  components: {
    baseNav,
    VueCropper,
  },
  data() {
    return {
      navList: ["修改账户头像"],
      current: 0,
      dataForm: {},
      loading: false,
      visible: false,
      img: "",
    };
  },
  created() {
    this.dataForm = { ...this.userInfo };
  },
  methods: {
    upload() {
      this.$refs.fileRef.click();
    },
    onClose() {
      this.visible = false;
    },
    //base64转换成file
    base64ImgtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    onSubmit() {
      this.loading = true;
      this.$refs.cropper.getCropData((data) => {
        let formDate = new FormData();
        formDate.append("file", this.base64ImgtoFile(data));
        formDate.append("fk_business_id", this.$store.state.businessId);
        Api.UploadFile(formDate).then((res) => {
          this.visible = false;
          this.loading = false;
          this.dataForm.headimgurl = res.data.file_path;
        });
      });
    },
    onChange(res) {
      let that = this;
      let file = res.target.files[0];
      if (file.size > 1048576 * 5) {
        this.$message.error("上传的图片大小不能超过5M");
        return;
      } else if (!/.(jpg|png|JPG|PNG)$/.test(res.target.value)) {
        this.$message.error("上传的图片格式不正确");
        return;
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        that.img = this.result;
      };
      this.visible = true;
    },
    onSave() {
      this.loading = true;
      Api.UpdateHeadimgurl({
        fk_user_id: this.userInfo.fk_user_id,
        headimgurl: this.dataForm.headimgurl,
      }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.$uStore("userInfo", res.data);
          this.loading = false;
        } else {
          this.$message.error(res.msg);
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cropper-box {
  width: 100%;
  height: 500px;
}
.headPortrait {
  .content-box {
    width: 100%;
    padding: 34px 32px;
    .img-box {
      display: flex;
      .upload-tip {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        padding-top: 16px;
        text-align: center;
      }
      .img-box-garden {
        margin-left: 34px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .header-img-garden {
          width: 160px;
          height: 160px;
          border-radius: 50%;
        }
        .img-tip {
          padding-top: 16px;
          font-size: 16px;

          font-weight: 400;
          color: #666666;
        }
      }
    }
    .info-btn-box {
      padding: 122px 0 130px 32px;
      display: flex;
      align-items: center;
      .ib-btn {
        width: 170px;
        height: 50px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #0086ff;
        color: #0086ff;
        font-size: 18px;

        font-weight: 400;
        color: #0086ff;
        letter-spacing: 2px;
        cursor: pointer;
        &.sumbit {
          background: #0086ff;
          color: #fff;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
