<template>
  <div class="password">
    <base-nav :list="navList" v-model="current" />
    <div class="content-box">
      <div class="info-box">
        <div class="info-name">登录手机</div>
        <div class="info-input">
          <el-input
            v-model="dataForm.mobile"
            :disabled="true"
            type="number"
            placeholder="请输入登陆手机"
            :clearable="true"
          ></el-input>
        </div>
      </div>
      <div class="info-box">
        <div class="info-name">验证码</div>
        <div class="info-input">
          <el-input
            v-model="dataForm.code"
            type="number"
            placeholder="请输入验证码"
            :clearable="true"
          ></el-input>
        </div>
        <div class="info-btn" @click="getCode(4)">{{ codeName }}</div>
      </div>
      <div class="info-box">
        <div class="info-name">新密码</div>
        <div class="info-input">
          <el-input
            v-model="dataForm.password"
            type="password"
            placeholder="请输入新密码"
            :clearable="true"
          ></el-input>
        </div>
      </div>
      <div class="info-btn-box">
        <div class="ib-btn sumbit" v-loading="loading" @click="onSubmit">
          保存设置
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseNav from "./base-nav";
import login from "@/mixins/login";
import Api from "@/api";
export default {
  components: {
    baseNav,
  },
  mixins: [login],
  data() {
    return {
      navList: ["修改登录密码"],
      current: 0,
      dataForm: {},
    };
  },
  created() {
    this.dataForm = { ...this.userInfo, code: "", password: "" };
    this.dataForm.phone = this.userInfo.mobile;
  },
  methods: {
    onSubmit() {
      this.loading = true;
      Api.UpdatePwd({
        fk_user_id: this.userInfo.fk_user_id,
        password: this.dataForm.password,
        smscode: this.dataForm.code,
      }).then((res) => {
        if (res.status == 1) {
          this.$alert("修改成功,请重新登陆", "提示", {
            showClose: false,
            confirmButtonText: "确定",
            callback: (action) => {
              if (action == "confirm") {
                this.$router.replace({
                  name: "login",
                });
                this.$store("userInfo", {});
              }
            },
          });
          this.loading = false;
        } else {
          this.$message.error(res.msg);
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./base.scss";
</style>
