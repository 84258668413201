import Api from "@/api";
export default {
    data() {
        return {
            dataForm: {
                phone: ''
            },
            checkList: {},
            timer: "",
            codeName: "发送验证码",
            disable: false,
            isAgreement: false,
            loading: false
        }
    },
    destroyed() {
        clearTimeout(this.timer)
    },
    methods: {
        initForm() {
            let key = Object.keys(this.rules);
            key.forEach((item) => {
                this.checkList[item] = {
                    checked: false,
                    msg: "",
                };
            });
        },
        getCode(type) {
            if (this.disable) {
                return;
            } else if (!this.dataForm.phone) {
                return this.$message.error("手机不能为空");
            }
            Api.SmsCode({
                type: type,
                mobile: this.dataForm.phone,
            }).then((res) => {
                if (res.status === 1) {
                    this.disable = true;
                    this.$message({
                        message: "验证码已发送",
                        type: "success",
                    });
                    this.setTime(60);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        checkForm(val) {
            if (this.rules[val] && this.rules[val].require) {
                if (this.rules[val] && this.rules[val].validator) {
                    let msg = this.rules[val].validator(this.dataForm[val]);
                    if (msg) {
                        this.$set(this.checkList[val], "checked", true);
                        this.$set(this.checkList[val], "msg", msg);
                    } else {
                        this.$set(this.checkList[val], "checked", false);
                    }
                } else {
                    if (!this.dataForm[val] || !this.dataForm[val].trim()) {
                        this.$set(this.checkList[val], "checked", true);
                        this.$set(
                            this.checkList[val],
                            "msg",
                            this.rules[val].message || "不能为空"
                        );
                    } else {
                        this.$set(this.checkList[val], "checked", false);
                    }
                }
                this.$forceUpdate();
            }
        },
        formCheck() {
            let bool = false;
            let key = Object.keys(this.dataForm);
            key.forEach((item) => {
                if (this.checkList[item] && this.checkList[item].checked) {
                    bool = true;
                } else if (!this.dataForm[item]) {
                    bool = true;
                }
            });
            return bool;
        },
        checkedAgreement() {
            this.isAgreement = !this.isAgreement;
        },
        setTime(num) {
            this.timer = setTimeout(() => {
                num--;
                if (num <= 0) {
                    this.disable = false;
                    this.codeName = "发送验证码";
                    clearTimeout(this.timer);
                    return;
                }
                this.codeName = num + "s";
                this.setTime(num);
            }, 1000);
        }
    }
}